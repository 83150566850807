import React from "react"
import { navigate } from "gatsby"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Column from "../../../../../components/column"
import Columns from "../../../../../components/columns"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import PaperStack from "../../../../../components/paper-stack"
import TaskSidebar from "../../../../../components/task-sidebar"
import Note from "../../../../../components/note"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Poem from "../../../../../components/poem"
import TextPicker from "../../../../../components/text-picker"

const pickerSet = [
  "😍",
  "😭",
  "😱",
  "🥰",
  "🥶",
  "😊",
  "😳",
  "😚",
  "😒",
  "😡",
  "🤔",
  "🤐",
  "🤯",
  "🤪",
  "🥴",
  "🤮",
  "🤧",
  "😇",
  "🥵",
  "😧",
  "😮",
  "🤨",
]

const Page = () => {
  const { submitForm } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          event,
          courseId: "haelfte-des-lebens",
          chapterId: "02-entstehungsgeschichte",
          taskId: "stimmungen-im-gedicht",
        })
        navigate(
          "/kurse/haelfte-des-lebens/02-entstehungsgeschichte/stimmungen-im-gedicht/zusammenfassung"
        )
      }}
      navigation={navigation}
      footer={<CourseFooterNext type="submit" value="submit" />}
    >
      <Seo title="Stimmungen im Gedicht" />
      <Stack>
        <Heading as="h2" level={2}>
          Stimmungen im Gedicht
        </Heading>
        <Paragraph>
          Wie passt das zu den Stimmungen im Gedicht? Welche Stimmungen findest
          du dort wieder?
        </Paragraph>

        <TaskSidebar
          main={
            <PaperStack>
              <Stack>
                <Columns>
                  <Column>
                    <Poem size={[3, 3, 4]}>
                      <p>Mit gelben Birnen hänget</p>
                      <p>Und voll mit wilden Rosen</p>
                      <p>Das Land in den See,</p>
                      <p>Ihr holden Schwäne,</p>
                      <p>Und trunken von Küssen</p>
                      <p>Tunkt ihr das Haupt</p>
                      <p>Ins heilignüchterne Wasser.</p>
                    </Poem>
                  </Column>
                  <Column width="content">
                    <Stack space={3}>
                      <TextPicker id="q-01" set={pickerSet} />
                      <TextPicker id="q-02" set={pickerSet} />
                      <TextPicker id="q-03" set={pickerSet} />
                    </Stack>
                  </Column>
                </Columns>
                <Columns>
                  <Column>
                    <Poem size={[3, 3, 4]}>
                      <p>Weh mir, wo nehm’ ich, wenn</p>
                      <p>Es Winter ist, die Blumen, und wo</p>
                      <p>Den Sonnenschein,</p>
                      <p>Und Schatten der Erde?</p>
                      <p>Die Mauern stehn</p>
                      <p>Sprachlos und kalt, im Winde</p>
                      <p>Klirren die Fahnen. </p>
                    </Poem>
                  </Column>
                  <Column width="content">
                    <Stack space={3}>
                      <TextPicker id="q-04" set={pickerSet} />
                      <TextPicker id="q-05" set={pickerSet} />
                      <TextPicker id="q-06" set={pickerSet} />
                    </Stack>
                  </Column>
                </Columns>
              </Stack>
            </PaperStack>
          }
          sidebar={
            <Note variant="task">
              Finde passende Emojis für die Stimmungen im Gedicht. Füge hinter
              jeder Strophe bis zu drei Emojis ein, indem du auf die großen
              Fragezeichen klickst.
            </Note>
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
